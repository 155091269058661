<template>
  <div class="container-fluid content">
    <notif-bar
      v-if="showNotifBar && $route.path != '/admin'"
      :message="adminMsg"
    />
    <countdown-timer
      v-if="showCountdown && $route.path == '/admin'"
      @update-countdown="updateCountdown"
    />
  </div>
</template>

<script>
  /* eslint-disable no-unused-vars */
  import { mapGetters, mapActions } from 'vuex';

  const NotifBar = () =>
  import('@/components/notification/NotificationBar.vue');
  const CountdownTimer = () =>
  import('@/components/notification/CountdownTimer.vue');

  export default {
    name : 'Listener',
    data() {
      return {
        adminMsg           : null,
        pnAdminInstance    : 'adminNotification',
        showNotifBar       : false,
        showCountdown      : false,
        activityMsg        : null,
        pnActivityInstance : 'activityNotification',
      }
    },
    created() {
      this.adminNotifListener();
      this.activityNotifListener();
      
      // this.getLiveBroadcastLink();
    },
    computed : {
      ...mapGetters({
        liveBroadcastLink      : 'broadcast/liveBroadcastLink',
        scheduledLiveBroadcast : 'broadcast/scheduledLiveBroadcast',
      }),
    },
    components : {
      CountdownTimer,
      NotifBar,
    },
    watch : {
      '$store.state.broadcast.scheduledLiveBroadcast.result'() {
        this.showCountdown = true;
      },
      activityMsg() {
        this.getActivityNotifs();
      },
    },
    methods : {
      ...mapActions({
        getLiveBroadcastLink      : 'broadcast/getLiveBroadcastLink',
        getAdminListNotifs        : 'notification/getAdminListNotifs',
        getActivityNotifs         : 'notification/getActivityNotifs',
        getAdminBarNotifs         : 'notification/getAdminBarNotifs',
        getScheduledLiveBroadcast : 'broadcast/getScheduledLiveBroadcast',
      }),

      /**
       * Admin Notification Listener
       */
      async adminNotifListener() {
        var pb = this.$pnGetInstance(this.pnAdminInstance);
        var VueThis = this;
        pb.addListener({
          message : await function(m) {
            // handle message
            // The channel for which the message belongs
            var channelName = m.channel;

            // The channel group or wildcard subscription match (if exists)
            var channelGroup = m.subscription;

            var pubTT = m.timetoken; // Publish timetoken
            var adminMsg = m.message.ntf.msg; // The Payload
            var publisher = m.message.ntf.publisherName; //The Publisher
            var notifTypeId = m.message.ntf.notifTypeId;
            VueThis.adminMsg = adminMsg;
            
            // 1 - new-video
            // 6 - new-featured-content
            if (notifTypeId === 1 || notifTypeId === 6)
              VueThis.getAdminListNotifs();
            
            // 7 - live-streaming-minutes
            // if (notifTypeId === 7) {
            //   VueThis.getAdminBarNotifs();
            //   VueThis.getScheduledLiveBroadcast();
            //   VueThis.showCountdown = true; // for admin
            //   VueThis.showNotifBar = true; // for user
            // }

            // 8 - live-streaming-start
            // if (notifTypeId === 8) {
              
            //   VueThis.getAdminBarNotifs();
            //   VueThis.showNotifBar = true;
            //   VueThis.getLiveBroadcastLink();
            // }

            // 9 - live-streaming-end
            // if (notifTypeId === 9) {
            //   VueThis.getAdminBarNotifs();
            //   VueThis.$store.commit('broadcast/CLEAR_LIVE_BROADCAST_LINK');
            //   VueThis.showNotifBar = true;
            // }
          },
        });
      },

      /**
       * Activity Notification Listener
       */
      async activityNotifListener() {
        var pb = this.$pnGetInstance(this.pnActivityInstance);
        var VueThis = this;
        pb.addListener({
          message : await function(m) {
            // Handle message
            // Channel where the message belong
            var channelName = m.channel;

            // Channel group or wildcard subscription match (if exists)
            var channelGroup = m.subscription;

            var pubTT = m.timetoken; // publish timetoken
            var activityMsg = m.message.ntf.msg; // payload
            var publisher = m.message.ntf.publisherName; // publisher
            var notifTypeId = m.message.ntf.notifTypeId;
            VueThis.activityMsg = activityMsg;

            // 2 - shared-content-request
            if (notifTypeId === 2 || notifTypeId === 3 || notifTypeId === 19)
              VueThis.getActivityNotifs();
          },
        });
      },

      /**
       * Close Notification
       */
      closeNotif() {
        this.msg = null;
        this.showNotifBar = false;
      },

      /**
       * Toggle Countdown Notification
       */
      updateCountdown() {
        this.showCountdown = !this.showCountdown;
      },
    },
    mounted() {
      this.$pnSubscribe({
        channels : ['admin-notif'],
      }, this.pnAdminInstance);
      this.$pnSubscribe({
        channels : [this.$store.getters['user/user'].chat_id],
      }, this.pnActivityInstance);

      if (this.$store.getters['user/user'] &&
        this.$store.getters['user/user'].user_type !== 0) {
        // this.getScheduledLiveBroadcast();
        this.getActivityNotifs();
        this.getAdminListNotifs();
        this.getAdminBarNotifs();
      }
      
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/components/notification/listener";
</style>
